<template>
  <div>
    <!-- Filter Controls -->
    <div v-if="campaignBursts">
      <v-row
        v-if="campaignBursts.length > 0"
        class="my-0 py-0"
      >
        <v-col
          cols="12"
          class="my-0 py-0"
        >
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <v-select
                v-model="selectedBurst"
                outlined
                label="Select Burst"
                :items="campaignBursts"
                item-text="name"
                return-object
                color="primary"
                @change="updateCampaignBurstScreens"
              />
            </v-col>

            <v-spacer />
            <v-col
              cols="12"
              md="3"
            >
              <v-select
                v-model="selectedDimension"
                outlined
                :items="dimensionGroupsFromScreens"
                label="Select Screens By Dimensions"
                clearable
                @change="selectScreensByDimensions"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row 
        v-else
      >
        <v-col>
          <span>No Bursts have been added to this campaign go to the campaign
            management page to create a burst.</span>
        </v-col>
      </v-row>
    </div>
    <!-- ************************************-->
    <v-row class="my-0 py-0">
      <!-- Burst Name -->
      <v-col
        v-if="selectedBurst"
        cols="6"
        class="ma-0 pa-0 px-1"
      >
        <v-icon large>
          mdi-flash
        </v-icon>
        <span class="text-h2">{{ selectedBurst.name }}</span>
      </v-col>

      <v-spacer />

      <!-- Upload Area -->
      <v-col
        v-if="showMediaUpload"
        cols="6"
        class="my-0 py-0 d-flex justify-end"
      > 
        <!-- drag & drop upload -->
        <div
          v-if="this.$store.state.Permissions.uploadMedia"
          @click="(selectedScreens.length > 0) ? $refs.mediaUpload.click() : selectScreenToast()"
          @drop.prevent="(event) => { 
            if (selectedScreens.length > 0) {
              dragFile(event); 
              dragFileUpload('mainDragDiv', 'transparent'); 
            } else
              selectScreenToast(); 
          }"
          @dragover.prevent="dragFileUpload('mainDragDiv', 'grey');"
          @dragleave="dragFileUpload('mainDragDiv', 'transparent');"
          @mouseover="dragFileUpload( 'mainDragDiv', 'grey')"
          @mouseleave="dragFileUpload( 'mainDragDiv', 'transparent')"
          style="width: 150px;"
        >
          <input
            @click="$refs.mediaUpload.value = ''"
            ref="mediaUpload"
            type="file"
            multiple
            hidden
            @change="uploadFile"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                id="mainDragDiv"
                v-bind="attrs"
                v-on="on"
                style="
                  background-color: transparent;
                  border-radius: 5%;
                  border: grey 1px dashed;
                  margin-bottom: 10px;
                  padding: 10px;
                "
              >
              <div style="width:100%; text-align:center;">
                  <v-icon
                    large
                    :color="
                      (selectedScreens.length === 0) 
                      ? (($vuetify.theme.dark) 
                      ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)')  
                      : (($vuetify.theme.dark) ? 'white' : 'gray')
                    "
                  >
                    mdi-cloud-upload
                  </v-icon>
                </div>
              </div>
            </template>
            <span>Upload Creatives</span>
          </v-tooltip>
        </div>

        <!-- Social media -->
         <v-tooltip v-if="showUploadSocial" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="ml-3 d-flex justify-center align-center"
              style="width: 60px; height: 60px; border-radius: 50%;"
            >
              <v-btn
                class="d-flex justify-center align-center"
                fab
                color="primary"
                :disabled="selectedScreens.length === 0"
                @click="showUploadCreativeDialog = true"
              >
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Upload Tweets</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <!-- Grid & List view + Select/Unselect options  -->
    <v-row v-if="campaignBurstScreens && campaignBurstScreens.length > 0" class="px-1">
      <!-- Grid view -->
      <v-col cols="auto" class="ma-0 px-1">
        <v-icon
          @click="setVueLocal('mediaViewType', 'grid')"
          :color="(mediaViewType === 'grid') ? 'primary' : invertColor('#000', $vuetify.theme.dark)"
          :style="{ fontSize: `${30}px` }"
        >mdi-view-grid-outline</v-icon>
      </v-col>

      <!-- List view -->
      <v-col cols="auto" class="ma-0 px-0">
        <v-icon
          @click="setVueLocal('mediaViewType', 'list')"
          :color="(mediaViewType === 'list') ? 'primary' : invertColor('#000', $vuetify.theme.dark)"
          class="mt-n1"
          :style="{ fontSize: `${38}px` }"
        >mdi-view-list-outline</v-icon>
      </v-col>

      <!-- Select/Unselect options -->
      <v-col cols="auto">
        <v-btn small @click="selectAll()">select all</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn small @click="clearSelection()">unselect all</v-btn>
      </v-col>
    </v-row>
    
    <!-- Screens -->
    <div>
      <!-- Grid View -->
      <v-row v-if="mediaViewType === 'grid'" class="px-1">
        <v-col
          v-for="(campaignBurstScreen, index) in campaignBurstScreens"
          :key="campaignBurstScreen.id"
          cols="auto"
          class="ma-0 mx-2 px-0"
        >
          <v-card
            elevation="2"
            class="ma-0 pa-0"
            width="370"
            height= "100%"
          >
            <v-card-title class="primary white--text ma-0 p-4" style="height: 5rem;">
              <v-row style="position: relative;">
                <v-col cols="2" class="ma-0 pa-0 d-flex align-center justify-center">
                  <v-checkbox
                    v-if="showMediaUpload"
                    v-model="campaignBurstScreen.screen.selected"
                    color="white"
                    class="pa-0 mx-0 ml-n2"
                    @change="screenSelected(campaignBurstScreen.screen)"
                  />
                </v-col>

                <v-col cols="10" class="ma-0 pa-0 d-flex align-center justify-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ attrs, on }">
                      <div
                        v-bind="attrs"
                        v-on="on" 
                        @click="viewAllCreatives(campaignBurstScreen)" 
                        class="ml-n7" 
                        style="width: 100%"
                      >
                        <v-tooltip
                          bottom
                          style="background: transparent"
                          :disabled="!showToolTip('screenName-' + index)"
                        >
                          <template v-slot:activator="{ attrs, on }">
                            <p
                              :id="'screenName-' + index"
                              v-bind="attrs"
                              v-on="on"
                              class="ma-0 pa-0 mb-n2 text-truncate" style="font-size: 15px"
                            >{{ campaignBurstScreen.screen.name }}</p>
                          </template>
                          <span>{{ campaignBurstScreen.screen.name }}</span>
                        </v-tooltip>
                        
                        <v-tooltip
                          bottom
                          style="background: transparent"
                          :disabled="!showToolTip('friendlyName-' + index)"
                        >
                          <template v-slot:activator="{ attrs, on }">
                            <p
                              :id="'friendlyName-' + index"
                              v-bind="attrs"
                              v-on="on"
                              class="ma-0 pa-0 mb-n1 text-truncate" style="font-size: 11px;"
                            >{{ campaignBurstScreen.screen.mediaOwner.friendlyName }}</p>
                          </template>
                        </v-tooltip>
                        <div class="d-flex mt-n3" style="gap: 0.5em">
                          <div class="mouse-pointer" style="font-size: 10px">{{ campaignBurstScreen.screen.width }}x{{ campaignBurstScreen.screen.height }}</div>
                          <div class="mouse-pointer" style="font-size: 10px">{{ campaignBurstScreen.screen.aspectRatio }}</div>
                        </div>
                      </div>
                    </template>
                    <span>View Creatives</span>
                  </v-tooltip>
                </v-col>
                
                <div v-if="screenSpecsView" style="position: absolute; top: 0; right: -3px;">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="mt-n2 grey--text text--darken-3"
                      >
                        <v-icon @click="viewScreenSpecs(campaignBurstScreen)" class="screen-info" style="font-size: 26px; transition: 0.6s;">mdi-information-outline</v-icon>
                      </div>
                    </template>
                    <span>View Screen Specs</span>
                  </v-tooltip>
                </div>
              </v-row>
            </v-card-title>

            <!-- If no perms for upload -->
            <div v-if="!PermissionsUploadMedia">
              <p>No Media Added.</p>
            </div>

            <div 
              v-else-if="gettingCreatives"
              class="d-flex justify-center align-center"
              :style="{
                height: 'calc(100% - 5rem)',
                minHeight: '380px',
                width: '100%',
              }"
            >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="70"
                  :width="7"
                ></v-progress-circular>
            </div>

            <!-- Screen content -->
            <div 
              v-else-if="
                !gettingCreatives &&
                renderImageSection &&
                campaignBurstScreen.campaignBurstScreenContents &&
                campaignBurstScreen.campaignBurstScreenContents.length > 0
              "
              style="height: calc(100% - 5rem)"
            >
              <!-- Top bar actions -->
              <v-row
                :style="{
                  height: '5rem',
                  marginTop: '1px',
                }"
              >
                <v-col
                  cols="2"
                  class="text-left"
                >
                  <v-icon
                    v-if="
                      campaignBurstScreen.campaignBurstScreenContents[
                        campaignBurstScreen.campaignBurstScreenContents
                          .mediaIndex
                      ].media.originalFileName
                        .split('.')
                        .pop() === 'mp4' ||
                        campaignBurstScreen.campaignBurstScreenContents[
                          campaignBurstScreen.campaignBurstScreenContents
                            .mediaIndex
                        ].media.originalFileName
                          .split('.')
                          .pop() === 'webm'
                    "
                    class="ml-3"
                    color="primary"
                  >
                    mdi-video
                  </v-icon>
                  <v-icon
                    v-else
                    color="primary"
                    class="ml-3"
                  >
                    mdi-camera
                  </v-icon>
                </v-col>
                <v-col
                  cols="8"
                  class="text-center"
                >
                  Creative
                  {{
                    campaignBurstScreen.campaignBurstScreenContents
                      .mediaIndex + 1
                  }}
                  out of
                  {{
                    campaignBurstScreen.campaignBurstScreenContents.length
                  }}
                </v-col>
                <v-col 
                  cols="2"
                  class="text-right"
                >
                  <v-tooltip
                    v-if="screenMediaRemove"
                    content-class="top"
                    top
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-icon  
                        @click.native.stop="confirmDeleteAllCreatives(campaignBurstScreen)"
                        color="red"
                        class="mr-3 mouse-pointer"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete-empty
                      </v-icon>
                    </template>
                    <span>Delete All Creatives</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <!-- Content -->
              <v-col
                v-if="campaignBurstScreen.campaignBurstScreenContents"
                :id="'dragDiv' + index"
                @click="
                  clickFileUpload()
                  holdSelection();
                  campaignBurstScreen.screen.selected = true;
                  screenSelected(campaignBurstScreen.screen);
                "
                @drop="(event) => {
                  holdSelection();
                  campaignBurstScreen.screen.selected = true;
                  screenSelected(campaignBurstScreen.screen);
                  dragFile(event);
                  dragFileUpload('dragDiv' + index, 'transparent');
                }"
                @dragleave="dragFileUpload('dragDiv' + index, 'transparent'); dragFilePlusUpload('dragDivPlus' + index, 'hide');"
                @dragover="dragFileUpload( 'dragDiv' + index, 'grey'); dragFilePlusUpload('dragDivPlus' + index, 'show');"
                @mouseleave="dragFileUpload( 'dragDiv' + index, 'transparent'); dragFilePlusUpload('dragDivPlus' + index, 'hide');"
                @mouseover="dragFileUpload( 'dragDiv' + index, 'grey'); dragFilePlusUpload('dragDivPlus' + index, 'show');"
                @dragover.prevent
                @drop.prevent
                cols="12"
                class="d-flex align-center justify-center"
                :style="{
                  position: 'relative',
                  height: 'calc(100% - 10rem)'
                }"
              >

                <!-- Videos -->
                <div
                  v-if="
                    campaignBurstScreen.campaignBurstScreenContents[
                      campaignBurstScreen.campaignBurstScreenContents
                        .mediaIndex
                    ].media.originalFileName
                      .split('.')
                      .pop() === 'mp4' ||
                      campaignBurstScreen.campaignBurstScreenContents[
                        campaignBurstScreen.campaignBurstScreenContents
                          .mediaIndex
                      ].media.originalFileName
                        .split('.')
                        .pop() === 'webm'
                  "
                  style="object-fit: cover"
                >
                  <video
                    style="
                        max-height: 250px;
                        max-width: 250px;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                      "
                    :src="
                      campaignBurstScreen.campaignBurstScreenContents[
                        campaignBurstScreen.campaignBurstScreenContents
                          .mediaIndex
                      ].media.url
                    "
                    controls
                  />
                </div>

                <!-- Images -->
                <v-img
                  v-else
                  contain
                  :src="
                    campaignBurstScreen.campaignBurstScreenContents[
                      campaignBurstScreen.campaignBurstScreenContents
                        .mediaIndex
                    ].media.url
                  "
                  max-height="250"
                />

                <!-- Upload cloud overlay (absolute)  -->
                <div
                  :id="'dragDivPlus' + index"
                  class="d-flex justify-center align-center"
                  style="
                    opacity: 0; 
                    width: 100%; 
                    height: 100%; 
                    position: absolute; 
                    left: 50%; 
                    top: 50%; 
                    transform: translate(-50%,-50%); 
                    background-color: rgba(128, 128, 128, 0.93);"
                >
                  <v-icon large style="font-size: 100px" color="rgba(255, 255, 255, 1)">mdi-cloud-upload</v-icon>
                </div>
              </v-col>

              <!-- Bottom bar actions -->
              <v-card-actions
                v-if="campaignBurstScreen.campaignBurstScreenContents"
                :style="{
                  height: '5rem',
                }"
              >
                <!-- Chevron left -->
                <v-btn
                  v-if="
                    campaignBurstScreen.campaignBurstScreenContents.length >
                      1
                  "
                  icon
                  @click="cycleMediaItem(campaignBurstScreen, 'decrease')"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>

                <!-- Delete creative -->
                <span>
                  <v-tooltip
                    content-class="top"
                    top
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        v-if="screenMediaRemove"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="red"
                        @click="deleteCreativeDialog(campaignBurstScreen, 'grid', 0)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Creative</span>
                  </v-tooltip>
                </span>

                <!-- Chevron right -->
                <v-btn
                  v-if="
                    campaignBurstScreen.campaignBurstScreenContents.length >
                      1
                  "
                  icon
                  @click="cycleMediaItem(campaignBurstScreen, 'increase')"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>

                <!-- Split -->
                <v-spacer />

                <!-- QR Code  -->
                <v-btn
                  v-if="false"
                  icon
                  color="secondary"
                  @click="qrCodeGenerateDialog = true"
                >
                  <v-icon>mdi-qrcode</v-icon>
                </v-btn>
                
                <!-- Edit triggers -->
                <v-tooltip
                  content-class="top"
                  top
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-if="triggerListEdit"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      @click="
                        triggersDialog = true;
                        selectedScreen = campaignBurstScreen;
                      "
                    >
                      <v-icon>mdi-movie-cog</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Triggers</span>
                </v-tooltip>
                
                <!-- View Creatives  -->
                <v-tooltip
                  content-class="top"
                  top
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      @click="viewAllCreatives(campaignBurstScreen)"
                    >
                      <v-icon>mdi-view-module</v-icon>
                    </v-btn>
                  </template>
                  <span>View Creatives</span>
                </v-tooltip>
              </v-card-actions>
            </div>

            <!-- Drag & Drop upload -->
            <div 
              v-else-if="
                !gettingCreatives
              "
              :style="{
                height: 'calc(100% - 5rem)',
                minHeight: '380px',
                width: '100%',
              }"
            >
              <!-- Top bar actions -->
              <v-row
                :style="{
                  height: '5rem',
                  marginTop: '0.8rem',
                }"
              >
                <!-- Camera Icon -->
                <v-col
                  cols="1"
                  class="text-left"
                >
                  <v-icon
                    color="primary"
                    class="ml-3"
                  >
                    mdi-camera
                  </v-icon>
                </v-col>
                <v-col
                  cols="1"
                  class="text-left"
                >
                  <v-icon
                    color="primary"
                    class="ml-3"
                  >
                    mdi-video
                  </v-icon>
                </v-col>

                <!-- title  -->
                <v-col
                  cols="8"
                  class="text-center"
                >
                  No Creative's Added
                </v-col>

                <!-- delete all -->
                <v-col
                  cols="2"
                  class="text-right"
                >
                  <v-tooltip
                    content-class="top"
                    top
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-icon  
                        color="grey"
                        class="mr-3 mouse-pointer"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete-empty
                      </v-icon>
                    </template>
                    <span>Disabled</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <!-- Drag and drop -->
              <div
                class="d-flex justify-center align-center"
                :style="{
                  height: 'calc(100% - 10rem)'
                }"
              >
                <!-- Upload -->
                <div
                  :id="'dragDiv' + index"
                  
                  @click="
                    holdSelection();
                    campaignBurstScreen.screen.selected = true;
                    screenSelected(campaignBurstScreen.screen);
                    clickFileUpload();
                  "

                  @drop="
                    holdSelection();
                    campaignBurstScreen.screen.selected = true;
                    screenSelected(campaignBurstScreen.screen);
                    dragFile($event);
                    dragFileUpload('dragDiv' + index, 'transparent');
                  "
                  @dragleave="dragFileUpload('dragDiv' + index, 'transparent')"
                  @dragover="dragFileUpload( 'dragDiv' + index, 'grey')"
                  @dragover.prevent
                  @drop.prevent

                  @mouseleave="dragFileUpload( 'dragDiv' + index, 'transparent')"
                  @mouseover="dragFileUpload( 'dragDiv' + index, 'grey')"
                  
                  class="d-flex justify-center align-center"
                  :style="{
                    height: '90%',
                    width: '75%',
                    marginTop: '20px',
                    backgroundColor: 'transparent',
                    border: '1px dotted grey',
                    borderRadius: '5px'
                  }"
                >
                  <!-- drag & drop upload -->
                  <div
                    style="width: 150px; "
                  >
                    <input
                      id="mediaUploadCard"
                      type="file"
                      multiple
                      hidden
                      @change="uploadFile"
                      @click.stop
                    />
                    <div>
                      <div style="width:100%; text-align:center;">
                        <v-icon style="font-size: 100px">
                          mdi-cloud-upload
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Bottom bar actions -->
              <v-card-actions>
                <!-- Delete creative -->
                <v-tooltip
                  content-class="top"
                  top
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-if="screenMediaRemove"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="grey"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Disabled</span>
                </v-tooltip>
                
                <!-- Split -->
                <v-spacer />

                <!-- QR Code  -->
                <v-btn
                  v-if="false"
                  icon
                  color="secondary"
                  @click="qrCodeGenerateDialog = true"
                >
                  <v-icon>mdi-qrcode</v-icon>
                </v-btn>
                
                <!-- Edit triggers -->
                <v-tooltip
                  content-class="top"
                  top
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-if="triggerListEdit"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      @click="
                        triggersDialog = true;
                        selectedScreen = campaignBurstScreen;
                      "
                    >
                      <v-icon>mdi-movie-cog</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Triggers</span>
                </v-tooltip>
                
                <!-- View Creatives  -->
                <v-tooltip
                  content-class="top"
                  top
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      @click="viewAllCreatives(campaignBurstScreen)"
                    >
                      <v-icon>mdi-view-module</v-icon>
                    </v-btn>
                  </template>
                  <span>View Creatives</span>
                </v-tooltip>
                
              </v-card-actions>
            </div>            
          </v-card>
        </v-col>
      </v-row>

      <!-- List View -->
      <v-row v-else-if="mediaViewType === 'list'" justify="center" class="px-3">
        <v-expansion-panels 
          v-if="campaignBurstScreens"
          multiple
          class="mt-3"
        >
          <v-expansion-panel
            v-for="(campaignBurstScreen, index) in campaignBurstScreens"
            :key="index"
          >
            <v-expansion-panel-header>
              <!-- checkbox + title -->
              <div class="d-flex align-center">
                <v-checkbox
                  v-if="showMediaUpload"
                  v-model="campaignBurstScreen.screen.selected"
                  @click.native.stop
                  class="pa-0 ma-0 mt-5"
                  @change="screenSelected(campaignBurstScreen.screen)"
                />
                {{ campaignBurstScreen.screen.name }}
                {{ ' - ' + campaignBurstScreen.screen.mediaOwner.friendlyName }}
                {{ campaignBurstScreen.screen.width }}x{{ campaignBurstScreen.screen.height }}
                {{ ' - ' + campaignBurstScreen.screen.aspectRatio }}
              </div>
              <!-- Quick action icons -->
              <div class="d-flex justify-end">
                <v-btn
                  icon
                  color="primary"
                  @click.native.stop="viewAllCreatives(campaignBurstScreen)"
                >
                  <v-icon>mdi-view-module</v-icon>
                </v-btn>
                <v-btn
                  v-if="triggerListEdit"
                  icon
                  color="primary"
                  @click.native.stop="
                    triggersDialog = true;
                    selectedScreen = campaignBurstScreen;
                  "
                >
                  <v-icon>mdi-movie-cog</v-icon>
                </v-btn>
                <v-btn
                  v-if="true"
                  icon
                  color="red"
                  @click.native.stop="confirmDeleteAllCreatives(campaignBurstScreen)"
                >
                  <v-icon>mdi-delete-empty</v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col
                v-if="campaignBurstScreen.campaignBurstScreenContents"
                :key="index"
                cols="12"
                class="pa-0 ma-0"
              >
                <VueSlickCarousel class="mx-5 mb-5 pb-2" v-bind="carouselSettings()" :style="{maxHeight: '200px'}">
                  <div
                    v-for="(item, i) in campaignBurstScreen.campaignBurstScreenContents"
                    :key="i"
                    :style="{
                      display: 'inline',
                      maxHeight: '200px'
                    }"
                  >
                    <!-- Image Container -->
                    <v-img
                      v-if="item.media.mimeType.includes('image')"
                      contain
                      :src="item.media.urlThumb"
                      max-height="200"
                      max-width="90%"
                      :style="{
                        position: 'relative',
                        margin: '0 5%',
                      }"
                    > 
                      <div
                        v-if="screenMediaRemove"
                        class="d-flex justify-center px-1"
                        :style="{ 
                          position: 'absolute', 
                          top: '3px',
                          left: 0,
                          right: 0,
                          height: '25px',
                        }"
                      >
                        <div
                          class="d-flex justify-center mouse-pointer"
                          :style="{
                            width: '26px',
                            height: '26px',
                            background: '#111',
                            borderRadius: '50%'
                          }"
                        >
                          <v-icon
                            @click.native.stop="deleteCreativeDialog(campaignBurstScreen, 'list', i)"
                            color="red" 
                            small
                          >mdi-delete</v-icon>
                        </div>
                      </div>
                    </v-img>

                    <!-- Video Container -->
                    <div
                      v-else-if="item.media.mimeType.includes('video')"
                      class="d-flex justify-center"
                      :style="{
                        position: 'relative',
                        maxHeight: '200px',
                      }"
                    >
                      <video
                        v-if="item.media.mimeType.includes('video')"
                        controls
                        :src="item.media.urlThumb"
                        :style="{
                          margin: '0 5%',
                          maxHeight: '200px',
                          maxWidth: '90%'
                        }"
                      />

                      <div
                        v-if="screenMediaRemove"
                        class="d-flex justify-center px-1"
                        :style="{ 
                          position: 'absolute', 
                          top: '3px',
                          left: 0,
                          right: 0,
                          height: '25px',
                        }"
                      >
                        <div
                          class="d-flex justify-center mouse-pointer"
                          :style="{
                            width: '26px',
                            height: '26px',
                            background: '#111',
                            borderRadius: '50%'
                          }"
                        >
                          <v-icon
                            @click.native.stop="deleteCreativeDialog(campaignBurstScreen, 'list', i)"
                            color="red" 
                            small
                          >mdi-delete</v-icon>
                        </div>
                      </div>

                    </div>
                  </div>
                </VueSlickCarousel>
              </v-col>
              <v-col 
                v-else
                cols="12"
              >
                No Media Added.
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
   
    <!-- Size mismatch -->
    <v-dialog
      v-model="sizeMismatchWarningDialog"
      width="500"
    >
      <v-card class="pa-3">
        {{ warningText }} would you like to proceed?
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="uploadWrongSizeCreative"
          >
            Confirm
          </v-btn>
          <v-btn
            color="red"
            @click="
              sizeMismatchWarningDialog = false;
              mismatchedFormatSizes = [];
              warningText = null;
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteWarning"
      width="500"
    >
      <v-card class="px-6 py-3">
        {{ warningText }}
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteCreativeFromBurstScreen"
          >
            Confirm
          </v-btn>
          <v-btn
            color="red"
            @click="
              deleteWarning = false;
              selectedItemForDeletion = null;
              warningText = null;
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteAllWarning"
      width="500"
    >
      <v-card class="pa-3">
        <p class="mt-2">{{ warningText }}</p>
        <p class="mt-6">Confirm by typing "creatives"</p>

        <v-text-field
          v-model="confirmDeleteAll"
          color="red"
          label="creatives"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteAllCreatives(); confirmDeleteAll = ''"
            :disabled="confirmDeleteAll !== 'creatives'"
          >
            Confirm
          </v-btn>
          <v-btn
            color="red"
            @click="
              deleteAllWarning = false;
              warningText = null;
              deleteAllScreen = null;
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


<!-- ******************************Edit Triggers************************** -->
    <v-dialog
      v-model="triggersDialog"
      :persistent="dataHasChanged"
      width="700"      
    >
      <v-card style="height: 500px;">
        <v-card-title class="white--text primary">
          Triggers
        </v-card-title>
        <v-col
          cols="12"
          class="pa-4"
          style="margin-bottom: 246px;"
          
        >
          <v-select
            v-model="selectedTriggers"
            @change="checkIfCanSave()"
            outlined
            multiple
            label="Available Triggers"
            item-text="friendlyName"
            item-value="paramName"
            :items="availableTriggers"
          />
        </v-col>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            @click="editTriggers(); checkIfCanSave()"
            :disabled="!dataHasChanged"
            :loading="submittingTrigger"
            color="primary"
            class="mx-1"
          >
            Save
          </v-btn>
          <v-btn
            @click="triggersDialog = false; selectedTriggers = originalTriggers; checkIfCanSave()"
            color="red"
            class="mx-1"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>

    <!-- *********************************End***************************** -->
    <v-dialog
      v-model="showUploadMediaDialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="white--text primary">
          Upload Media
        </v-card-title>
        <v-card-text>
          <!-- Files selected title -->
          <v-row>
            <p>MP4 and Jpeg recommended</p>
          </v-row>
          <v-row>
            <h4>Files Selected:</h4>
          </v-row>

          <!-- Files selected -->
          <v-row>
            <ul>
              <li v-for="(media, index) in uploadedMedia" :key="index">
                {{media.name}}
                <v-tooltip
                  content-class="top"
                  top
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="removeUploadMedia(media)"
                      small  
                      color="black"
                      class="fab-icon ml-1"
                    >
                      mdi-close
                    </v-icon>
                  </template>
                  <span>Remove</span>
                </v-tooltip>
              </li>
            </ul>
          </v-row>

          <!-- Screens Selected title + error icon -->
          <v-row justify="space-between" align="center">
            <h4>Screens Selected:</h4>
            <v-icon
              v-if="showSelectedScreensErrors"
              large
              color="red"
            >mdi-shield-alert-outline</v-icon>
          </v-row>

          <!-- Screens selected -->
          <v-row>
            <ul v-if="showSelectedScreens">
              <li v-for="(showSelectedScreen, index) in showSelectedScreens" :key="index">
                {{showSelectedScreen.mediaOwner.friendlyName}} - {{showSelectedScreen.name}} 
                ({{showSelectedScreen.width}}x{{showSelectedScreen.height}})
                <v-tooltip
                  content-class="top"
                  top
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="removeUploadScreen(showSelectedScreen.id)" 
                      small  
                      color="black"
                      class="fab-icon ml-1"
                    >mdi-close</v-icon>
                  </template>
                  <span>Deselect</span>
                </v-tooltip>
                
              </li>
            </ul>
          </v-row>

          <!-- Upload buttons -->
          <v-row
            justify="center"
            class="mt-3"
          >
            <!-- Confirm upload button -->
            <v-btn
              class="mx-3"
              color="primary"
              :disabled="uploadedMedia === null || mediaUploadingFlag"
              :loading="mediaUploadingFlag"
              @click="confirmUploadMedia();"
            >
              Confirm
            </v-btn>

            <!-- Decline upload button -->
            <v-btn
              class="mx-3"
              color="red"
              @click="cancelUploadMedia(); releaseSelection();"
            >
              Cancel
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Socials Upload Dialog -->
    <v-dialog
      v-model="showUploadCreativeDialog"
      width="500"
    >
      <v-card>
        <v-card-title 
          class="white--text primary"
        >
          Upload Media
        </v-card-title>
        <v-card-text 
          class="pa-4"
        >
          <v-col 
            cols="12"
          >
            <v-text-field
              v-if="this.$store.state.Permissions.uploadSocial"
              v-model="socialMediaUrl"
              outlined
              label="Extract Media From Twitter"
            />
          </v-col>

          <v-col
            cols="12"
            class="ml-4"
          >
          <div class="d-flex justify-space-between">
            <h4>Screens Selected:</h4>
            <v-icon
              v-if="showSelectedScreensErrors"
              large
              color="red"
            >mdi-shield-alert-outline</v-icon>
          </div>
            
            <ul v-if="showSelectedScreens">
              <li v-for="(showSelectedScreen, index) in showSelectedScreens" :key="index">
                {{showSelectedScreen.mediaOwner.friendlyName}} - {{showSelectedScreen.name}} 
                ({{showSelectedScreen.width}}x{{showSelectedScreen.height}})
              </li>
            </ul>
          </v-col>

          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              color="primary"
              :disabled="socialMediaUrl === null || mediaUploadingFlag"
              :loading="mediaUploadingFlag"
              @click="confirmUploadToCreatives"
            >
              Upload
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="qrCodeGenerateDialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="white--text primary"
        >
          Generate QR Code
        </v-card-title>
        <v-row class="px-4">
          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="qrCodeUrl"
              outlined
              label="URL"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="correctionLevel"
              :items="correctionLevels"
              label="Correction Level"
              outlined
            />
          </v-col>
        </v-row>
        <v-row
          class="px-4"
          justify="center"
        >
          <v-col
            cols="12"
            class="text-center"
          >
            <vue-qrcode
              v-if="qrCodeUrl"
              id="qrCodeImg"
              :value="qrCodeUrl"
              width="300"
              :error-correction-level="correctionLevel"
            />
            <v-img
              v-else
              class="mx-auto"
              src="qr-placeholder.png"
              width="300"
            />
          </v-col>
        </v-row>
        <v-row class="pa-4">
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              color="primary"
              @click="downloadQrCode"
            >
              Download QR Code
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // Mixins
  import helpersColor from '@/mixins/helpers/color'
  import localStorage from '@/mixins/local/storage'
  import collectionSort from '@/mixins/collection/sort'

  import MediaTypeController from '@/services/controllers/MediaType'
  import CampaignController from '@/services/controllers/Campaign'
  const triggersList = require(`@/assets/${process.env.VUE_APP_TRIGGERS_VERSION}`)
  import VueQrCode from 'vue-qrcode'
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

  export default {
    mixins: [
      helpersColor,
      localStorage,
    ],

    components: {
      VueQrCode, VueSlickCarousel 
    },
    
    data: () => ({
      submittingTrigger: false,
      dataHasChanged: false,
      availableTriggers: [],
      triggersDialog: false,
      originalTriggers: [],
      selectedTriggers: [], 
      confirmDeleteAll: '',
      mediaViewType: 'grid',
      selectedScreen: null,
      mediaUploadingFlag: false,
      sizeMismatchWarning: false,
      selectedDimension: null,
      mismatchedFormatSizes: [],
      allScreensSelected: false,
      selectedScreens: [],
      selectedScreensTemp: [],
      mediaResOnUpload: null,
      deleteWarning: false,
      deleteAllWarning: false,
      deleteAllScreen: null,
      warningText: null,
      creatives: [],
      sizeMismatchWarningDialog: false,
      selectedBurst: null,
      dimensionGroupsFromScreens: [],
      campaignBurstScreens: null,
      campaignBursts: null,
      renderImageSection: false,
      uploadFromSocialMedia: false,
      uploadFromFile: false,
      qrCodeGenerateDialog: false,
      showQrCode: true,
      qrCodeUrl: null,
      correctionLevels: ['low', 'medium', 'quartile', 'high'],
      correctionLevel: 'medium',
      uploadedFileCreative: null,
      uploadedMedia: [],
      showUploadCreativeDialog: false,
      showUploadMediaDialog: false,
      socialMediaUrl: null,
      qrCodeHeight: null,
      qrCodeWidth: null,
      selectedItemForDeletion: null,
      gettingCreatives: false,
    }),
    
    created () {
      // If local storage variables are set, then map them to the local variables
      this.initVueLocal(['mediaViewType'])

      this.getCampaignById()

      for (var i = 0; i < triggersList.triggers.length; i++) {
        this.availableTriggers.push(triggersList.triggers[i])
      }
    },

    computed: {
      screenSpecsView() {
        return this.$store.state.Permissions.screenSpecsView
      },
      
      campaignBurstScreensChunk () {
        return _.chunk(Object.values(this.campaignBurstScreens), 3)
      },

      screenMediaRemove() {
        return this.$store.state.Permissions.screenMediaRemove
      },

      triggerListEdit() {
        return this.$store.state.Permissions.triggerListEdit
      },

      showMediaUpload() {
        return (this.$store.state.Permissions.uploadMedia && this.$store.state.Permissions.uploadSocial) ? true : false
      },

      showUploadSocial() {
        return this.$store.state.Permissions.uploadSocial
      },

      PermissionsUploadMedia() {
        return this.$store.state.Permissions.uploadMedia
      },

      showSelectedScreens() {
        let screens = []
        
        this.selectedScreens.forEach((id) => {
          this.campaignBurstScreens.forEach((item) => {
            if(item.screen.id === id) 
              screens.push(item.screen)
          })
        })
        return collectionSort(screens, 'dateCreated',  'asc')
      },

      showSelectedScreensErrors() {
        let error = false; let prev = null; let curr = null
        if(this.selectedScreens) {
          this.selectedScreens.forEach((id) => {
            this.campaignBurstScreens.forEach((item) => {
              if(item.screen.id === id) {
                prev = curr
                curr = item.screen
                error = (prev !== null && (curr.width !== prev.width || curr.height !== prev.height)) ? true : error
              }
            })
          })
        }

        return error
      },
    },
    
    watch: {
      selectedScreen: function (newVal, oldVal) {
        this.selectedTriggers = newVal.triggerList
        this.originalTriggers = null
        this.originalTriggers = [...this.selectedTriggers]
      },
    },

    methods: {
      checkIfCanSave() {
        // Check if the triggers have changed
        this.dataHasChanged = false
        let foundAmt = 0

        this.originalTriggers.forEach(og => {
          let found = false
          this.selectedTriggers.forEach(sl => { found = (found || og === sl) ? true : false })
          foundAmt += (found) ? 1 : 0
        })

        this.dataHasChanged = (foundAmt !== this.originalTriggers.length || foundAmt !== this.selectedTriggers.length) ? true : false
      },

      showToolTip(id) {
        const elem = document.getElementById(id)
        
        return elem && elem.scrollWidth > elem.clientWidth
      },

      clickFileUpload() {
        const fileUpload = document.getElementById('mediaUploadCard')
        if (fileUpload != null) {
          fileUpload.click()
        }
      },

      dragFileUpload(id, bgColor) {
        if (document.getElementById(id) != null) {
          document.getElementById(id).style.backgroundColor = bgColor
        }
      },

      dragFilePlusUpload(id, show) {
        const fileUpload = document.getElementById(id)
        if (fileUpload != null) {
          fileUpload.style.opacity = (show === 'hide') ? 0 : 1
        }
      },

      carouselSettings() {
        return {
          "dots": true,
          "slidesToShow": 3,
          "slidesToScroll": 1,
        }
      },
      
      async confirmDeleteAllCreatives(screen) {
        this.deleteAllWarning = true
        this.warningText = `Are you sure you want to delete all creatives from ${screen.screen.mediaOwner.friendlyName + ' - ' + screen.screen.mediaOwner.name}?`
        this.deleteAllScreen = screen
      },

      async deleteAllCreatives() {
        // Success var for delete all creatives 
        let success = false

        // Loop through and delete
        for(let i = 0; i < this.deleteAllScreen.campaignBurstScreenContents.length; i++) {
          const item = this.deleteAllScreen.campaignBurstScreenContents[i]
          await CampaignController.deleteBurstContent({
            campaignBurstScreenId: item.campaignBurstScreenId,
            campaignBurstScreenContentId: item.id,
          })
          .then(() => { success = true }, success)
          .catch(() => { success = false }, success)
        }

        // Show toast message
        this.$root.$emit(
          (success) ? 'snackbarSuccess' : 'snackbarError',
          (success) ? 'Successfully deleted creatives from burst screen.' : 'Failed to delete creatives from burst screen.',
        )

        // Hide modal
        this.deleteAllWarning = false
        this.deleteAllScreen = null
        // Refresh campaign
        await this.getCampaignById()
      },
      
      clearSelection() {
        this.selectedScreens = []

        this.campaignBurstScreens.forEach(item => { item.screen.selected = false })
      },

      holdSelection() {
        this.selectedScreensTemp = [...this.selectedScreens]

        this.clearSelection()
      },

      releaseSelection() {
        if(this.selectedScreensTemp.length > 0) {
          this.clearSelection()
          this.selectedScreens = [...this.selectedScreensTemp]
          this.selectedScreensTemp = []

          this.campaignBurstScreens.forEach(item => {
            this.selectedScreens.forEach(selected => {
              if(selected === item.screen.id)
                item.screen.selected = true
            })
          })
        }
      },

      selectAll() {
        this.selectedScreens = []

        this.campaignBurstScreens.forEach(item => { 
          item.screen.selected = true
          this.selectedScreens.push(item.screen.id) 
        })
      },

      async editTriggers() {
        this.submittingTrigger = true 
        await CampaignController.editBurstScreen({
          id: this.selectedScreen.id,
          slotBreakSeconds: this.selectedScreen.slotBreakSeconds,
          slotDurationSeconds: this.selectedScreen.slotDurationSeconds,
          triggerList: this.selectedTriggers,
        })
        .then(() => {
          // Refetch screen
          this.renderImageSection = false
          this.triggersDialog = false

          this.getCampaignById()

          this.$root.$emit(
            'snackbarSuccess',
            'Successfully scheduled event to screen.',
          )

          this.$store.dispatch('Clipboard/emptyClipboard');
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })

        this.submittingTrigger = false
        this.dataHasChanged = false
      },

      screenSelected(screen) {
        Array.prototype.remove = function () {
          var what
          var a = arguments
          var L = a.length
          var ax
          while (L && this.length) {
            what = a[--L]
            while ((ax = this.indexOf(what)) !== -1) {
              this.splice(ax, 1)
            }
          }
          return this
        }
        if (screen.selected === true) {
          this.selectedScreens.push(screen.id)
        } else {
          this.selectedScreens.remove(screen.id)
        }
      },

      viewAllCreatives(item) {
        this.$router.push({
          name: 'Artwork',
          query: {
            sid: item.screen.id,
            cbsid: item.id,
            cid: this.$route.query.cid,
            bid: this.selectedBurst.id,
            bName: this.selectedBurst.name,
          },
        })
      },

      viewScreenSpecs(item) {
        this.$router.push({
          name: 'Artwork',
          query: {
            sid: item.screen.id,
            cbsid: item.id,
            cid: this.$route.query.cid,
            bid: this.selectedBurst.id,
            bName: this.selectedBurst.name,
            tab: 'screenSpecs'
          },
        })
      },

      downloadQrCode() {
        const link = document.createElement('a')
        link.href = document.getElementById('qrCodeImg').getAttribute('src')
        link.download = 'qrcode.png'
        link.click()
        this.qrCodeUrl = null
        this.qrCodeWidth = null
        this.qrCodeGenerateDialog = false
      },

      cycleMediaItem(item, operation) {
        this.renderImageSection = false
        if (operation === 'increase') {
          if (
            item.campaignBurstScreenContents.mediaIndex <
            item.campaignBurstScreenContents.length - 1
          ) {
            item.campaignBurstScreenContents.mediaIndex =
              item.campaignBurstScreenContents.mediaIndex + 1
          } else {
            item.campaignBurstScreenContents.mediaIndex = 0
          }
        } else {
          if (item.campaignBurstScreenContents.mediaIndex > 0) {
            item.campaignBurstScreenContents.mediaIndex =
              item.campaignBurstScreenContents.mediaIndex - 1
          } else {
            item.campaignBurstScreenContents.mediaIndex =
              item.campaignBurstScreenContents.length - 1
          }
        }
        this.renderImageSection = true
      },

      async getCampaignById() {
        if (this.selectedScreens && this.selectedScreens.length > 0)
          this.holdSelection()

        this.gettingCreatives = true

        await CampaignController.getCampaign(this.$route.query.cid)
        .then((res) => {
          this.campaignBursts = res.data.campaignBursts

          if (this.$route.query.bid !== null) {
            for (let i = 0; i < this.campaignBursts.length; i++) {
              if (this.campaignBursts[i].id === parseInt(this.$route.query.bid)) {
                this.selectedBurst = this.campaignBursts[i]

                const curr = JSON.stringify({...this.$route.query, bid: this.$route.query.bid.toString()})
                const replace = JSON.stringify({ ...this.$route.query, bid: this.selectedBurst.id.toString()})

                if(curr !== replace)
                  this.$router.replace({ query: { ...this.$route.query, bid: this.selectedBurst.id } })
              }
            }
          } 
          else {
            this.selectedBurst = this.campaignBursts[0]

            const curr = JSON.stringify({...this.$route.query, bid: this.$route.query.bid.toString()})
            const replace = JSON.stringify({ ...this.$route.query, bid: this.campaignBursts[0].id.toString()})

            if(curr !== replace)
              this.$router.replace({ query: { ...this.$route.query, bid: this.campaignBursts[0].id } })
          }
          this.campaignBurstScreens = this.selectedBurst.campaignBurstScreens
          this.updateCampaignBurstScreens()
        })

        if (this.selectedScreensTemp && this.selectedScreensTemp.length > 0)
          this.releaseSelection()
      },

      uploadWrongSizeCreative() {
        // If user wants to upload creative to screens of wrong dimensions
        this.renderImageSection = false
        for (var i = 0; i < this.mismatchedFormatSizes.length; i++) {
          CampaignController.addContent({
            mediaId: this.mediaResOnUpload.id,
            triggers: '{}',
            campaignBurstScreenId: this.campaignBurstScreens.find(
              (x) => x.screen.id === this.mismatchedFormatSizes[i].screen.id,
            ).id,
          })
            .then(() => {
              this.updateCampaignBurstScreens()
              this.$root.$emit( 'snackbarSuccess', 'Succesfully uploaded creative to selected screen(s).')
              this.selectedScreens = []
              this.mismatchedFormatSizes = []
              this.mediaResOnUpload = null
              this.sizeMismatchWarningDialog = false
              this.warningText = null
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+ err.response.data.message)
            })
        }
      },

      async deleteCreativeFromBurstScreen() {
        this.gettingCreatives = true
        this.renderImageSection = false

        await CampaignController.deleteBurstContent({
          campaignBurstScreenId: this.selectedItemForDeletion.id,
          campaignBurstScreenContentId: this.selectedItemForDeletion.campaignBurstScreenContents[this.selectedItemForDeletion.campaignBurstScreenContents.mediaIndex].id,
        })
        .then(async () => {
          await this.getCampaignById()
          this.selectedItemForDeletion = null
          this.deleteWarning = false
          this.$root.$emit('snackbarSuccess', 'Successfully deleted creative from burst screen.')
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })
      },

      confirmUploadToCreatives() {
        // Uploads social content (twitter/instagram) to the selected screens on the burst
        if (this.socialMediaUrl !== null) {
          this.renderImageSection = false
          // Uploading has started flag (use for a spinner etc)
          this.mediaUploadingFlag = true
          // If extracting image from tweet
          const formData = new FormData()
          formData.append('url', this.socialMediaUrl)
          formData.append('campaignId', parseInt(this.$route.query.cid))
          
          MediaTypeController.uploadMediaAsUrl(formData)
          .then((res) => {
            // Loop through the selected screens,
            for (var i = 0; i < this.selectedScreens.length; i++) {
              for (var x = 0; x < res.data.length; x++) {
                CampaignController.addContent({
                  mediaId: res.data[x].media.id,
                  triggers: res.data[x].trigger,
                  campaignBurstScreenId: this.campaignBurstScreens.find(
                    (x) => x.screen.id === this.selectedScreens[i],
                  ).id,
                })
                  .then((res) => {
                    this.mediaUploadingFlag = false

                    this.$root.$emit(
                      'snackbarSuccess',
                      'Successfully uploaded creative to selected screen(s).',
                    )
                    this.showUploadCreativeDialog = false
                    this.socialMediaUrl = null
                    this.uploadFromSocialMedia = false
                    this.uploadFromFile = false
                    this.updateCampaignBurstScreens()
                  })
                  .catch((err) => {
                    this.mediaUploadingFlag = true
                    this.$root.$emit(
                      'snackbarError',
                      ''+ err.response.data.message,
                    )
                  })
              }
            }
          })
          .catch((err) => {
            this.mediaUploadingFlag = true
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
        }
      },

      async confirmUploadMedia() {
        this.gettingCreatives = true

        // Uploads media to the selected screens on the burst
        if (this.uploadedMedia !== null) {
          this.renderImageSection = false

          // Loop through to allow multiple file upload
          for (let index = 0; index < this.uploadedMedia.length; index++) {
            const item = this.uploadedMedia[index];
            
            // Uploading has started flag (use for a spinner etc)
            this.mediaUploadingFlag = true
            // If uploading from file input instead.
            const formData = new FormData()
            formData.append('file', item)
            formData.append('campaignId', parseInt(this.$route.query.cid))
            
            await CampaignController.uploadMedia(formData).then((res) => {
              for (var i = 0; i < this.selectedScreens.length; i++) {
                CampaignController.addContent({
                  mediaId: res.data.id,
                  triggers: '{}',
                  campaignBurstScreenId: this.campaignBurstScreens.find(
                    (x) => x.screen.id === this.selectedScreens[i],
                  ).id,
                })
                .then(() => {
                  this.updateCampaignBurstScreens()
                  this.$root.$emit('snackbarSuccess', 'Successfully uploaded creative to selected screen(s).')
                  this.showUploadCreativeDialog = false
                  this.socialMediaUrl = null
                  this.uploadFromSocialMedia = false
                  this.uploadFromFile = false
                })
                .catch((err) => {
                  this.$root.$emit('snackbarError', ''+ err.response.data.message)
                })
              }
            })
          }

          this.mediaUploadingFlag = false
          this.uploadedMedia = []
          this.showUploadMediaDialog = false
          this.releaseSelection();
        }
      },

      onFileChanged(e) {
        // Create form to upload media api
        const formData = new FormData()
        formData.append('file', event.target.files[0])
        formData.append('campaignId', parseInt(this.$route.query.cid))
        CampaignController.uploadMedia(formData)
          .then((mediaRes) => {
            const mediaIdRes = mediaRes.data.id
            let counterSizeCheck = 0
            for (var i = 0; i < this.selectedScreens.length; i++) {
              counterSizeCheck++
              this.mediaResOnUpload = mediaRes.data
              if (
                this.campaignBurstScreens.find(
                  (x) => x.screen.id === this.selectedScreens[i],
                ).screen.height !== mediaRes.data.height ||
                this.campaignBurstScreens.find(
                  (x) => x.screen.id === this.selectedScreens[i],
                ).screen.width !== mediaRes.data.width
              ) {
                this.mismatchedFormatSizes.push(
                  this.campaignBurstScreens.find(
                    (x) => x.screen.id === this.selectedScreens[i],
                  ),
                )
              } else {
                CampaignController.addContent({
                  mediaId: mediaIdRes,
                  triggers: '{}',
                  campaignBurstScreenId: this.campaignBurstScreens.find(
                    (x) => x.screen.id === this.selectedScreens[i],
                  ).id,
                })
                  .then(() => {
                    this.renderImageSection = false
                    this.updateCampaignBurstScreens()
                    this.$root.$emit(
                      'snackbarSuccess',
                      'Succesfully uploaded creative to selected screen(s).',
                    )
                    this.selectedScreens = []
                  })
                  .catch((err) => {
                    this.$root.$emit('snackbarError', ''+ err.response.data.message)
                  })
              }
              if (counterSizeCheck === this.selectedScreens.length) {
                if (this.mismatchedFormatSizes.length > 0) {
                  this.warningText = `The uploaded creative size is ${mediaRes.data.width}x${mediaRes.data.height}, the selected screen sizes are `
                  for (var x = 0; x < this.mismatchedFormatSizes.length; x++) {
                    this.warningText +=
                      this.mismatchedFormatSizes[x].screen.width +
                      'x' +
                      this.mismatchedFormatSizes[x].screen.height +
                      ' '
                  }
                  this.sizeMismatchWarningDialog = true
                }
              }
            }
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      deleteCreativeDialog(item, type, i) {
        item.campaignBurstScreenContents.mediaIndex = (type === 'grid') ? item.campaignBurstScreenContents.mediaIndex : i
        
        this.deleteWarning = true
        this.warningText = `Are you sure you want to delete ${
            item.campaignBurstScreenContents[item.campaignBurstScreenContents.mediaIndex].media.originalFileName
        } from ${item.screen.name}?`
        this.selectedItemForDeletion = item
      },

      getDimensionGroups() {
        const sizes = []
        for (var i = 0; i < this.campaignBurstScreens.length; i++) {
          sizes.push(
            this.campaignBurstScreens[i].screen.width +
              'x' +
              this.campaignBurstScreens[i].screen.height,
          )
        }
        this.dimensionGroupsFromScreens = sizes
      },

      async updateCampaignBurstScreens() {
        this.gettingCreatives = true

        this.campaignBurstScreens = this.selectedBurst.campaignBurstScreens

        // Don't replace curr url if it matches the new url
        const curr = JSON.stringify({...this.$route.query, bid: this.$route.query.bid.toString()})
        const replace = JSON.stringify({ ...this.$route.query, bid: this.selectedBurst.id.toString()})

        if(curr !== replace)
          this.$router.replace({ query: { ...this.$route.query, bid: this.selectedBurst.id } })
        
        // Get creatives by burst id
        await CampaignController.getBurstContent(this.selectedBurst.id).then((res) => {
          const campaignBurstContent = res.data
          const search = item => campaignBurstContent.find((element) => element.campaignBurstScreenId === item)
          for ( var i = 0; i < this.selectedBurst.campaignBurstScreens.length; i++ ) {
            if (search(this.campaignBurstScreens[i].id) !== undefined) {
              this.campaignBurstScreens[i].campaignBurstScreenContents = search(this.campaignBurstScreens[i].id).campaignBurstScreenContents
              this.campaignBurstScreens[i].campaignBurstScreenContents.mediaIndex = 0
              this.renderImageSection = true
            }
          }
        })
        setTimeout(() => {
          this.getDimensionGroups()
        }, 100)

        this.gettingCreatives = false
      },

      selectScreensByDimensions() {
        this.selectedScreens = []
        for (var x = 0; x < this.campaignBurstScreens.length; x++) {
          this.campaignBurstScreens[x].screen.selected = false
        }
        for (var i = 0; i < this.campaignBurstScreens.length; i++) {
          if (
            this.campaignBurstScreens[i].screen.width +
            'x' +
            this.campaignBurstScreens[i].screen.height ===
            this.selectedDimension
          ) {
            this.campaignBurstScreens[i].screen.selected = true
            this.selectedScreens.push(this.campaignBurstScreens[i].screen.id)
          }
        }
      },

      uploadFile (e) {
        this.uploadedMedia = Object.values(e.target.files)
        this.showUploadMediaDialog = (this.selectedScreens.length > 0) ? true : false
      },

      dragFile (e) {
        // Accept the file
        this.uploadedMedia = Object.values(e.dataTransfer.files)

        // Shorthand for if a screen is selected
        this.showUploadMediaDialog = 
          (this.selectedScreens.length > 0 && this.selectedScreens.length > 0) ? true : false
        
        // Don't allow if file/s dragged into upload box
        if(!this.showUploadMediaDialog) {
          this.selectScreenToast()
          this.cancelUploadMedia()
        }
      },

      cancelUploadMedia() {
        // Empty array
        this.uploadedMedia = []
        // Close upload modal
        this.showUploadMediaDialog = false
        
        this.releaseSelection()
      },

      selectScreenToast() {
        // Pops up when clicking on upload button with no screen selected
        this.$root.$emit('snackbarError', 'Please select a screen before uploading.')
      },

      removeUploadScreen(selectedItem) {
        // Filter the cards checkbox
        this.campaignBurstScreens.forEach(item => {
          if(item.screen.id === selectedItem)
            item.screen.selected = false
        })

        // Filter the selected screens
        this.selectedScreens = this.selectedScreens.filter(item => {
          if(item !== selectedItem)
            return item
        })
        
        // If list empty -> close upload modal
        if(this.selectedScreens.length === 0)
          this.cancelUploadMedia()
      },

      removeUploadMedia(selectedItem) {
        // Filter out removed file
        this.uploadedMedia = [...this.uploadedMedia].filter(item => {
          if (item.name !== selectedItem.name)
            return item
        })

        // If list empty -> close upload modal
        if(this.uploadedMedia.length === 0)
          this.cancelUploadMedia()
      }
    },
  }
</script>

<style lang="scss">
  .fab-icon {
    padding: 1px;
    font-size: 14px !important;
    background: rgb(180, 180, 180);
    border-radius: 50%;
    
    &:hover {
      background: rgb(244, 67, 54);
    }
  }

  .screen-info:hover {
    color: #dcea41 !important;
  }
</style>
